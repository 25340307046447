import {inject} from '@angular/core'
import {from, Observable, of, switchMap} from 'rxjs'
import {Router} from '@angular/router'
import {ClosedService} from '../services/closed.service'

export const closedGuard = (): Observable<boolean> => {

  const service = inject(ClosedService)
  const router = inject(Router)

  return service.isClosed().pipe(
    switchMap((closed) => {
      if (closed) {
        return from(router.navigate(['/', 'closed']))
      }
      return of(!closed)
    })
  )
}


